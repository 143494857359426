.App {
	padding: 20px;
}

.container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	padding-right: 15px;
	padding-left: 15px;
	gap: 1.5rem;
}

.form-container,
.result-container {
	min-width: 300px; /* Ensure a minimum width for each container */
	margin: 10px;
}

.form-container {
	width: 100%;
}

.form-container form {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.result-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

@media (min-width: 476px) {
	.container {
		width: 100%;
	}
}
@media (min-width: 768px) {
	.container {
		flex-direction: row;
		width: 720px;
		max-width: 100%;
	}

	.form-container {
		width: 40%;
	}
}
@media (min-width: 992px) {
	.container {
		width: 960px;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1140px;
		max-width: 100%;
	}
}
@media (min-width: 1400px) {
	.container {
		width: 1340px;
		max-width: 100%;
	}
}
